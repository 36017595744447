#asideloginhelp
{
    position: absolute;
    width: 500px;
    height: auto;
    border: 4px solid #DDDDDD;
    padding: 8px;
    left: 50%;
    margin-left: -250px;
    top: 50%;
    margin-top: -110px;
    z-index: 1001;
    display: none;
    background: #FFFFFF;
    font: normal 0.8em/1 inherit;
}

    #asideloginhelp ul {
        margin: 10px 0 0 12px;
        padding: 4px;
        counter-reset: list-number;
        list-style-type: decimal;
    }

        #asideloginhelp ul > li {
            padding: 0;
            margin: 4px 0;
            counter-increment: list-number;
        }