span.encrypted,
span.ssl,
span.signed {
    border-radius: 4px;
    padding: 2px;
    color: #000000 !important;
    font-weight: bold;
    border: 2px solid #3B3B3B;
}

span.encrypted {
    background: #CCEEFF;
}

span.ssl {
    background: #CCD7FF;
}

span.signed {
    background: #E9E0FF;
}

div[data-role="searchquerycontainer"] input[type="text"]
{
    margin-top: 3px;
}

div.alignright
{
    text-align: right;
    margin: 12px 0;
    padding: 0;
    clear: both;
    display: block;
}