[data-role="searchquerycontainer"]
{
    border: 1px solid #777;
    width: 380px;
    border-radius: 4px;
    padding: 0 0 0 4px;
    display: inline-block;
}


[data-role="searchquerycontainer"] input[type="text"]
{
    border: none;
    padding-top: 2px;
    width: calc(100% - 80px);
}

[data-role="searchquerycontainer"] input[type="text"]:focus
{
    border: none;
    outline: none;
}

[data-role="searchquerycontainer"] input[type="text"] + .hydrated
{
	float: right;
}

[data-role="searchquerycontainer"] + span
{
    display: inline-block;
    margin-left: 8px;
}

.reset,
.reset *
{
    color: #DF0088 !important;
}