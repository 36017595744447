a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}

@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

.box-shadow {
	box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

header {
	background: #3C289B;
	padding: 0;
}

	header .navbar-nav a
	{
		color: #FFFFFF !important;
		text-decoration: none;
	}

	header .navbar-brand {
		font-size: 2.5em;
		color: #FFFFFF !important;
		padding-top: 1rem !important;
		padding-bottom: 0 !important;
	}

	header .navbar-light .navbar-nav .nav-link,
	header a.navbar-brand + .navbar-nav > li.nav-item {
		color: #FFFFFF !important;
		padding-left: .75rem !important;
		padding-right: .5rem !important;
		padding-top: 1rem !important;
		padding-bottom: 0 !important;
		font-size: 1rem !important;
		font-weight: 500 !important;
	}

	header a.navbar-brand + .navbar-nav {
		width: calc(100% - 200px);
		justify-content: space-between;
		margin-left: auto;
	}

		header a.navbar-brand + .navbar-nav > li.nav-item.leftside {
			align-self: flex-start;
		}

		header a.navbar-brand + .navbar-nav > li.nav-item.rightside {
			align-self: flex-end;
			margin-left: auto;
		}

		header a.navbar-brand + .navbar-nav > li.nav-item:hover a {
			text-decoration: underline;
		}

	header nav {
		line-height: 0.75 !important;
	}

.inlineblock {
	display: inline-block;
}

header .navbar-nav a.activelink
{
	text-decoration: underline !important;
	font-weight: bold;
}

header .homelink
{
	color: #FFFFFF !important;
	text-decoration: none;
}

header .navbar-header
{
    position: relative !important;
    z-index: 1;
}