section.x12fileuploadcontainer
{
    width: 100%;
    clear: both;
    display: block;
    margin: auto;
}

section.x12fileuploadcontainer > div[data-role="dropzone"],
section.x12fileuploadcontainer > div[data-role="dropzonefiles"]
{
    border-radius: 16px;
	margin: 8px;
	width: 100%;
}

	section.x12fileuploadcontainer > div[data-role="dropzone"]
	{
		border: 4px dashed #3C289B;
		min-height: 100px;
		transition: 0.05s all ease;
	}

	section.x12fileuploadcontainer > div[data-role="dropzone"].activedrag
	{
		opacity: 0.5;
		border-color: #999999;
	}

	section.x12fileuploadcontainer > div[data-role="dropzonefiles"] {
		background: #ECECEC;
		padding: 32px;
	}

section.x12fileuploadcontainer > div[data-role="dropzone"] input[type="file"]
{
	display: none;
}

.uploadinstructions
{
    display: block;
    margin: 12px auto;
    padding: 12px;
    color: #999999;
	text-align: center;
    font: bold 1em/1 Tahoma, Verdana, sans-serif;
}

ul[data-role="uploadedfiles"]
{
	margin: 0;
	padding: 0;
}

ul[data-role="uploadedfiles"] > li
{
	list-style-type: none;
	margin: 0;
	display: flex;
	padding: 0 0 0 4px;
	border-top: 1px solid #AAAAAA;
	border-bottom: 0 solid #AAAAAA;
}

ul[data-role="uploadedfiles"] > li:hover *
{
	background-color: #DEDEDE;
}

ul[data-role="uploadedfiles"] > li:last-child
{
	border-bottom-width: 1px;
}

ul[data-role="uploadedfiles"] > li > div[data-role="fileinfo"]
{
	width: calc(100% - 50px);
}

ul[data-role="uploadedfiles"] > li > div[data-role="fileactions"]
{
	width: 50px;
}