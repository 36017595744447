.certificatedetails
{
    width: 300px;
}

.errorMessage
{
    color: #FF0000;
    font-weight: bold;
    font-size: 0.95em;
    text-align: left;
    margin: 0;
    padding: 0;
}

div.note
{
    margin-top: -8px;
    padding-top: 0;
    display: block;
    font-size: 0.8em;
}

div[data-role="group"]
{
    margin-bottom: 16px;
    clear: both;
}

[data-role="detailheader"]
{
    display: flex;
    clear: both;
    justify-content: center;
    align-content: center;
    margin: auto;
    padding: 4px;
    background: #ECEAF5;
    border: 1px solid #B6AED9;
    margin-bottom: 16px;
}

[data-role="detailheader"] > div
{
    flex: 1 0 auto;
}

[data-role="detailheader"] > div:last-child
{
    justify-content: right;
    align-content: end;
    text-align: right;
    padding-right: 16px;
}

table.renewalfunctions
{
    width: 99%;
    margin: auto;
    padding: 0;
    text-align: left;
    margin-top: 24px;
}

table.renewalfunctions tr td 
{
    vertical-align: top;
    max-width: 33%;
    padding: 0 24px 24px 0;
    text-align: left;
    min-height: 120px;
}

    table.renewalfunctions tr td.threecolumn
    {
        width: 33%;
    }

    table.renewalfunctions tr td.twocolumn {
        width: 50%;
    }

    table.renewalfunctions tr:first-child td {
        height: auto;
    }

p.centered.instruction
{
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    margin: 40px auto 0 auto;
    line-height: 1.25;
    color: #656565;
    width: 400px;
    border: 2px solid #777777;
    padding: 12px;
    border-radius: 16px;
}

table[data-role="subdetailheader"]
{
    display: block;
    clear: both;
    margin: 0;
    width: 100%;
    background: #ECEAF5;
    overflow: auto;
    border: 1px solid #B6AED9;
}

    table[data-role="subdetailheader"] > thead,
    table[data-role="subdetailheader"] > tbody
    {
        display: table;
        width: 100%;
    }

[data-role="subdetailheader"] th
{
    width: 20%;
    background: #E9E7F0;
    border-bottom: 1px solid #DDD;
    padding: 2px;
}

[data-role="subdetailheader"] td
{
    width: 20%;
    padding: 2px;
}

.seventimes {
	font-size: 2em;
}

table.renewalfunctions tr > td.expirationemailcell
{
	vertical-align: top;
	text-align: center;
	margin: auto;
}

table.renewalfunctions tr td.expirationemailcell > div.centered
{
	display: block;
	margin: 4px auto;
	line-height: 1;
}

table.renewalfunctions tr td.expirationemailcell a
{
	text-decoration: none;
	font-weight: bold;
}

.emailmodal
{
    clear:both;
    text-align: left;
    font-weight: bold;
    margin: 4px 0;
    padding: 0;
}

.emailmodal.error
{
    color: #800000;
}

.emailmodal.success
{
    color: #008800;
}