body.login {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

    body.login a,
    body.login a:active,
    body.login a:visited {
        color: #FFFFFF;
        font-weight: normal;
        text-decoration: none;
    }

        body.login a:hover {
            text-decoration: underline;
        }

#logincontainer {
    width: 98vw;
    height: 98vh;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

    #logincontainer input[type="text"],
    #logincontainer input[type="password"] {
        width: 400px !important;
    }

[data-role="login"] {
    min-width: 549px;
    max-height: 552px;
    background: #FFFFFF;
    border-radius: 6px;
    /*flex: 1 1 549px;*/
    padding: 60px;
}

    [data-role="login"] a {
        color: #3C289B !important;
    }

    [data-role="login"] hr {
        width: 100%;
        margin: 4px auto;
        height: 1px;
        padding: 10px;
        border: 1px solid #DDDDDD;
        border-width: 1px 0 0 0;
    }

    [data-role="login"] h1 {
        margin: auto;
        text-align: center;
        font-weight: normal;
        font-size: 4em;
        line-height: 1.75;
        color: var(--trueblue);
    }

    [data-role="login"] h3 {
        margin: auto;
        text-align: center;
    }

[data-role="companylogo"] {
    height: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 32px;
    left: 32px;
}

    [data-role="companylogo"].cencora {
        background-image: url('../images/cencora_logo_white.png');
        width: 160px;
    }

    [data-role="companylogo"].abc {
        background-image: url('../images/ab_logo_white.png');
        width: 220px;
    }

    [data-role="companylogo"] h4 {
        display: none;
        color: #FFFFFF;
    }

span.required {
    color: #880000;
    display: inline-block;
    margin-right: 2px;
    font-weight: bold;
}

[data-role="formgroup"] {
    margin: 2px 0 10px 0;
    padding: 0;
}

[data-role="loginfooter"] {
    width: 100%;
    height: auto;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.75em;
    margin-top: 100px;
}

    [data-role="loginfooter"] ul {
        margin: 0 auto;
        padding: 0;
        width: 90%;
    }

        [data-role="loginfooter"] ul li {
            list-style-type: none;
            display: inline-block;
            margin: 0;
            padding: 2px 0;
        }
