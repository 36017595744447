/* Provide sufficient contrast against white background */
*,
html body,
html body *
{
    font-family: 'AB Gilroy Regular' !important;
}

a {
  color: #3C289B;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.modalbackground
{
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: rgba(0,0,0,0.75);
    display: none;
    top: 0;
    left: 0;
    z-index: 1000;
}

.modalbackground.visible
{
    display: block;
}

.centered
{
    text-align: center !important;
    margin: auto;
}

.right
{
    text-align: right;
}