:root {
	--abcblue: #3C289B;
	--trueblue: #3C289B;
}

.btn.btn-primary {
	background-color: var(--trueblue) !important;
}

.center {
	text-align: center !important;
	margin: auto !important;
}

[data-role="buttongroup"] {
	margin-top: 24px;
	width: 100%;
	padding: 0;
	clear: both;
}

	[data-role="buttongroup"].float-right > * {
		text-align: right;
		float: right;
	}

	[data-role="buttongroup"].float-left > * {
		text-align: left;
		float: left;
	}

.button, input, select {
	font-size: .93rem !important;
	font-weight: 500 !important;
}
