table.absgtable {
    width: 100%;
    margin: 12px auto 0 auto;
    padding: 0;
    clear: both;
    border: 1px solid #C7C2E3;
    border-collapse: collapse;
    font-size: 1em;
    line-height: 1;
}

    table.absgtable > thead > tr > th
    {
        background: #3C289B;
        color: #FFFFFF;
        font-weight: bold;
        text-align: left;
        padding: 4px;
        margin: 0;
    }

    table.absgtable > thead > tr > th a {
        color: #FFFFFF !important;
    }

    table.absgtable > tbody > tr > td {
        border-width: 0 1px 1px 0;
        border-color: #C7C2E3;
        border-style: solid;
        color: #3B3B3B;
		padding: 4px;
    }

    table.absgtable > tbody > tr:nth-child(2n+1) > td {
        background: #FFFFFF;
    }

    table.absgtable > tbody > tr:nth-child(2n+2) > td {
        background: #ECEAF5;
    }

    table.absgtable > tbody > tr > td span[title]
    {
        display: inline-block;
        margin-right: 2px;
    }

    table.absgtable > tbody > tr > td span[title]:last-child {
        margin-right: 0;
    }

/*******************************************************************************/

table.absgtable.certificates > thead > tr > th:nth-child(5)
{
    width: 130px;
}

table.absgtable.certificates > thead > tr > th:nth-child(6)
{
    width: 170px;
}

/*******************************************************************************/

table.absgtable.auxiliary > thead > th
{
    background-color: #EDEDED !important;
    color: #343434 !important;
    border-right: 1px solid #A299CE;
}

table.absgtable.auxiliary > thead > th:last-child
{

}

table.absgtable.auxiliary > tbody > tr > td
{
    border-width: 0 1px 1px 0;
    border-color: #A299CE;
    border-style: solid;
}

/*******************************************************************************/

table.absgtable.auxiliary.urltable
{
    margin-top: 0;
    border-top-width: 0;
}

table.absgtable.auxiliary.urltable tr td:first-child
{
    width: 150px !important;
}