.communitysearch
{
    width: 300px;
}

div[data-role="group"]
{
    margin-bottom: 16px;
    clear: both;
}

.communitysearchcontainer tr td
{
    padding-right: 16px;
    vertical-align: middle;
}