footer
{
    background-color: #F5F5F5;
    color: #3B3B3B;
}

footer div.d-flex
{
    padding-bottom: 12px;
}

footer div.d-flex > div {
    flex: 1 1 33%;
    text-align: center;
    font-size: 0.8em;
}

footer div.d-flex > div:last-child
{
    text-align: right;
}

footer div.d-flex .navbar-nav > li
{
    display: inline-block;
    margin-right: 0;
    padding: 4px 0;
    max-height: 30px;
}

footer div.d-flex .navbar-nav > li a
{
    color: #474747 !important;
    font-weight: bold;
}

footer div.d-flex .navbar-nav > li:last-child
{
    margin-right: 0;
}

footer img.logo
{
    display: inline-block;
    margin: 32px 16px 0 0;
    border: none !important;
}