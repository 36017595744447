[data-role="userprofile"]
{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-items: center;
    width: 500px;
    margin: 0;
    padding: 0;
    clear: both;
    justify-content: flex-start;
}

[data-role="userprofile"] > div
{
    flex: 0 1 auto;
    padding: 0 8px 0 0;
}

[data-role="userprofile"] > div:first-child
{
    width: 120px !important;
    vertical-align: top;
    padding-top: 0;
}

.usericon
{
    font-size: 6em;
    color: var(--abcblue);
}

.usericon.hidden
{
    visibility: hidden;
}

[data-role="profilegroup"]
{
    margin-bottom: 17px;
}

div > [data-role="profilegroup"]:last-child
{
    margin-bottom: 0;
}