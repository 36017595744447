html, body {
	margin: 0;
	padding: 0;
	font-family: "AB Gilroy", Helvetica, sans-serif;
	width: 100%;
	height: 100%;
}

.rightside {
	text-align: right;
	margin-right: 4px;
}

.leftside {
	text-align: left;
	margin-left: 4px;
}

.contentflex {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

	.contentflex > header {
		flex-shrink: 0;
		flex-grow: 0;
	}

	.contentflex > .content {
		flex-grow: 1;
		padding-top: 20px;
	}

		.contentflex > .content > div {
			width: 90%;
			margin: 0 auto 12px auto;
		}

		.contentflex > .content > .contentcontainer {
			width: 90%;
			margin: auto;
			clear: both;
		}

footer {
	flex-shrink: 0;
}

hr.abcblue {
	width: 100%;
	border-width: 1px 0 0 0;
	border-color: #B5B5B5;
	border-style: solid;
}

.contentflex > .content h1,
.contentflex > .content h2,
.contentflex > .content h3,
.contentflex > .content h4,
.contentflex > .content h5,
.contentflex > .content h6 {
	color: var(--abcblue);
}

.contentflex > .content h3 {
	font-size: 2rem !important;
	font-weight: 600 !important;
}

.contentflex > .content h4 {
	font-size: 1.5rem !important;
	font-weight: 600 !important;
}

.contentflex > .content h5 {
	font-size: 1rem !important;
	font-weight: 500 !important;
}

.contentflex > .content h6 {
	font-size: 0.875rem !important;
	font-weight: 500 !important;
}
