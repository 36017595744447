@font-face {
    font-family: 'AB Gilroy Black';
    /*src: local('☺︎'), url('source/abgilroy/black/abgilroy-black.woff') format('woff');*/
    src: url('source/abgilroy/black/abgilroy-black.eot');
    src: url('source/abgilroy/black/abgilroy-black.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/black/abgilroy-black.woff') format('woff'),
         url('source/abgilroy/black/abgilroy-black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Black';
    /*src: local('☺︎'),  url('source/abgilroy/black/abgilroy-blackitalic.woff') format('woff');*/
    src: url('source/abgilroy/black/abgilroy-blackitalic.eot');
    src: url('source/abgilroy/black/abgilroy-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/black/abgilroy-blackitalic.woff') format('woff'),
         url('source/abgilroy/black/abgilroy-blackitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AB Gilroy Black';
    /*src: local('☺︎'),  url('source/abgilroy/black/abgilroy-extrabold.woff') format('woff');*/
    src: url('source/abgilroy/black/abgilroy-extrabold.eot');
    src: url('source/abgilroy/black/abgilroy-extrabold.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/black/abgilroy-extrabold.woff') format('woff'),
         url('source/abgilroy/black/abgilroy-extrabold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Black';
    /*src: local('☺︎'),  url('source/abgilroy/black/abgilroy-extrabolditalic.woff') format('woff');*/
    src: url('source/abgilroy/black/abgilroy-extrabolditalic.eot');
    src: url('source/abgilroy/black/abgilroy-extrabolditalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/black/abgilroy-extrabolditalic.woff') format('woff'),
         url('source/abgilroy/black/abgilroy-extrabolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/****************************************/

@font-face {
    font-family: 'AB Gilroy Heavy';
    /*src: local('☺︎'),  url('source/abgilroy/heavy/abgilroy-semibold.woff') format('woff');*/
    src: url('source/abgilroy/heavy/abgilroy-semibold.eot');
    src: url('source/abgilroy/heavy/abgilroy-semibold.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/heavy/abgilroy-semibold.woff') format('woff'),
         url('source/abgilroy/heavy/abgilroy-semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Heavy';
    /*src: local('☺︎'),  url('source/abgilroy/heavy/abgilroy-semibolditalic.woff') format('woff');*/
    src: url('source/abgilroy/heavy/abgilroy-semibolditalic.eot');
    src: url('source/abgilroy/heavy/abgilroy-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/heavy/abgilroy-semibolditalic.woff') format('woff'),
         url('source/abgilroy/heavy/abgilroy-semibolditalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AB Gilroy Heavy';
    /*src: local('☺︎'),  url('source/abgilroy/heavy/abgilroy-heavy.woff') format('woff');*/
    src: url('source/abgilroy/heavy/abgilroy-heavy.eot');
    src: url('source/abgilroy/heavy/abgilroy-heavy.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/heavy/abgilroy-heavy.woff') format('woff'),
         url('source/abgilroy/heavy/abgilroy-heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Heavy';
    /*src: local('☺︎'),  url('source/abgilroy/heavy/abgilroy-heavyitalic.woff') format('woff');*/
    src: url('source/abgilroy/heavy/abgilroy-heavyitalic.eot');
    src: url('source/abgilroy/heavy/abgilroy-heavyitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/heavy/abgilroy-heavyitalic.woff') format('woff'),
         url('source/abgilroy/heavy/abgilroy-heavyitalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/****************************************/

@font-face {
    font-family: 'AB Gilroy Light';
    /*src: local('☺︎'),  url('source/abgilroy/light/abgilroy-light.woff') format('woff');*/
    src: url('source/abgilroy/light/abgilroy-light.eot');
    src: url('source/abgilroy/light/abgilroy-light.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/light/abgilroy-light.woff') format('woff'),
         url('source/abgilroy/light/abgilroy-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Light';
    /*src: local('☺︎'),  url('source/abgilroy/light/abgilroy-lightitalic.woff') format('woff');*/
    src: url('source/abgilroy/light/abgilroy-lightitalic.eot');
    src: url('source/abgilroy/light/abgilroy-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/light/abgilroy-lightitalic.woff') format('woff'),
         url('source/abgilroy/light/abgilroy-lightitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AB Gilroy Light';
    /*src: local('☺︎'),  url('source/abgilroy/light/abgilroy-medium.woff') format('woff');*/
    src: url('source/abgilroy/light/abgilroy-medium.eot');
    src: url('source/abgilroy/light/abgilroy-medium.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/light/abgilroy-medium.woff') format('woff'),
         url('source/abgilroy/light/abgilroy-medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Light';
    /*src: local('☺︎'),  url('source/abgilroy/light/abgilroy-mediumitalic.woff') format('woff');*/
    src: url('source/abgilroy/light/abgilroy-mediumitalic.eot');
    src: url('source/abgilroy/light/abgilroy-mediumitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/light/abgilroy-mediumitalic.woff') format('woff'),
         url('source/abgilroy/light/abgilroy-mediumitalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/****************************************/

@font-face {
    font-family: 'AB Gilroy Regular';
    /*src: local('☺︎'),  url('source/abgilroy/regular/abgilroy-regular.woff') format('woff');*/
    src: url('source/abgilroy/regular/abgilroy-regular.eot');
    src: url('source/abgilroy/regular/abgilroy-regular.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/regular/abgilroy-regular.woff') format('woff'),
         url('source/abgilroy/regular/abgilroy-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Regular';
    /*src: local('☺︎'),  url('source/abgilroy/regular/abgilroy-regularitalic.woff') format('woff');*/
    src: url('source/abgilroy/regular/abgilroy-regularitalic.eot');
    src: url('source/abgilroy/regular/abgilroy-regularitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/regular/abgilroy-regularitalic.woff') format('woff'),
         url('source/abgilroy/regular/abgilroy-regularitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AB Gilroy Regular';
    /*src: local('☺︎'),  url('source/abgilroy/regular/abgilroy-bold.woff') format('woff');*/
    src: url('source/abgilroy/regular/abgilroy-bold.eot');
    src: url('source/abgilroy/regular/abgilroy-bold.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/regular/abgilroy-bold.woff') format('woff'),
         url('source/abgilroy/regular/abgilroy-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Regular';
    /*src: local('☺︎'),  url('source/abgilroy/regular/abgilroy-bolditalic.woff') format('woff');*/
    src: url('source/abgilroy/regular/abgilroy-bolditalic.eot');
    src: url('source/abgilroy/regular/abgilroy-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/regular/abgilroy-bolditalic.woff') format('woff'),
         url('source/abgilroy/regular/abgilroy-bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/****************************************/

@font-face {
    font-family: 'AB Gilroy Thin';
    /*src: local('☺︎'),  url('source/abgilroy/thin/abgilroy-thin.woff') format('woff');*/
    src: url('source/abgilroy/thin/abgilroy-thin.eot');
    src: url('source/abgilroy/thin/abgilroy-thin.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/thin/abgilroy-thin.woff') format('woff'),
         url('source/abgilroy/thin/abgilroy-thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Thin';
    /*src: local('☺︎'),  url('source/abgilroy/thin/abgilroy-thinitalic.woff') format('woff');*/
    src: url('source/abgilroy/thin/abgilroy-thinitalic.eot');
    src: url('source/abgilroy/thin/abgilroy-thinitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/thin/abgilroy-thinitalic.woff') format('woff'),
         url('source/abgilroy/thin/abgilroy-thinitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'AB Gilroy Thin';
    /*src: local('☺︎'),  url('source/abgilroy/thin/abgilroy-ultralight.woff') format('woff');*/
    src: url('source/abgilroy/thin/abgilroy-ultralight.eot');
    src: url('source/abgilroy/thin/abgilroy-ultralight.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/thin/abgilroy-ultralight.woff') format('woff'),
         url('source/abgilroy/thin/abgilroy-ultralight.ttf') format('truetype');
    font-weight: normal;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AB Gilroy Thin';
    /*src: local('☺︎'),  url('source/abgilroy/thin/abgilroy-ultralightitalic.woff') format('woff');*/
    src: url('source/abgilroy/thin/abgilroy-ultralightitalic.eot');
    src: url('source/abgilroy/thin/abgilroy-ultralightitalic.eot?#iefix') format('embedded-opentype'),
         url('source/abgilroy/thin/abgilroy-ultralightitalic.woff') format('woff'),
         url('source/abgilroy/thin/abgilroy-ultralightitalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}