#filehotspot
{
    clear: both;
    width: 70%;
    margin: 16px auto;
    min-height: 500px;
    border-radius: 32px;
    border: 4px dashed #0094FF;
    padding: 32px;
}

.statuslabel
{
    display: inline-block;
    margin-left: 4px;
    font-weight: bold;
}

.green {
    color: #01bb0a;
}

.crimson {
    color: #880000;
}

.redorange {
    color: #d96705;
}
