[data-role="messagecontainer"]
{
    width: 100%;
}

body.login [data-role="messagecontainer"]
{
    width: 610px;
}

.messagenotification
{
    clear: both;
    font: normal 1em "AB Gilroy", sans-serif;
    margin: 20px auto 20px auto;
    padding: 8px;
    width: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 1em;
    border: 3px solid #DBDBDB;
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.75);
}

.messagenotification > div
{
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: normal;
}

.messagenotification [data-role="iconcontainer"]
{
    width: 100%;
    padding: 2px 3px;
}

.messagenotification [data-role="iconcontainer"] span > span
{
    text-indent: -99999px;
    display: inline-block;
}

.messagenotification [data-role="contentcontainer"]
{
    width: auto;
    max-width: 90%;
}

.messagenotification [data-role="contentcontainer"] > div
{
    display: block;
    clear: both;
    padding: 0 0 0 4px;
    margin: 0 0 8px 0;
}

.messagenotification [data-role="contentcontainer"] [data-role="masthead"]
{
    font-size: 1.25em !important;
    font-weight: bold;
    text-transform: uppercase;
}

.messagenotification [data-role="contentcontainer"] > [data-role="standardmessage"]
{
    font-size: 1.2em;
}

.messagenotification [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist
{
    margin: 0;
    padding: 0 0 0 12px;
    font-size: 1em;
}

.messagenotification [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist > li
{
    margin: 0 0 2px 14px;
    padding: 0;
    list-style-type: disc;
}

/******************************************/

.messagenotification.success
{
    background: #DDFFDD;
    border-color: #339900;
}

.messagenotification.success > [data-role="iconcontainer"]
{
    color: #339900;
}

.messagenotification.success > [data-role="contentcontainer"] > [data-role="masthead"],
.messagenotification.success > [data-role="contentcontainer"] > [data-role="standardmessage"],
.messagenotification.success > [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist > li
{
    color: #207000;
}

/******************************************/

.messagenotification.error
{
    background: #FFDDDD;
    border-color: #993300;
}

.messagenotification.error > [data-role="iconcontainer"]
{
    color: #993300;
}

.messagenotification.error > [data-role="contentcontainer"] > [data-role="masthead"],
.messagenotification.error > [data-role="contentcontainer"] > [data-role="standardmessage"],
.messagenotification.error > [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist > li
{
    color: #702000;
}

/******************************************/

.messagenotification.information
{
    background: #E7EFF5;
    border-color: #E6E6EF;
}

.messagenotification.information > [data-role="iconcontainer"]
{
    color: #435D6F;
}

.messagenotification.information > [data-role="contentcontainer"] > [data-role="masthead"],
.messagenotification.information > [data-role="contentcontainer"] > [data-role="standardmessage"],
.messagenotification.information > [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist > li
{
    color: #435D6F;
}

/******************************************/

.messagenotification.warning
{
    background: #FFFFCC;
    border-color: #EFEF99;
}

.messagenotification.warning > [data-role="iconcontainer"]
{
    color: #666600;
}

.messagenotification.warning > [data-role="contentcontainer"] > [data-role="masthead"],
.messagenotification.warning > [data-role="contentcontainer"] > [data-role="standardmessage"],
.messagenotification.warning > [data-role="contentcontainer"] > [data-role="messagelistcontainer"] > ul.detailslist > li
{
    color: #606000;
}

/******************************************/

.messagenotification table
{
    width: 100%;
}

.messagenotification td.iconcell
{
    width: 50px !important;
    vertical-align: top;
}

.messagenotification td.iconcell .bi
{
    font-size: 3em;
}

.messagenotification td.contentcell
{
    padding-left: 10px;
}