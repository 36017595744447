[data-role="selfserviceinformation"]
{
    width: 800px;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: start;
    justify-content: flex-start;
    flex-flow: row;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;
}

[data-role="selfserviceinformation"] > [data-role="informationitem"]
{
    width: 50%;
    flex: 0 0 50%;
    margin: 0;
    padding: 0 4px 4px 0;
}

.selfservicetextboxcontainer
{
    width: 300px;
    display: inline-block;
}

[data-role="downloadtracker"]
{
    clear: both;
    text-align: right;
    display: flex;
    flex: 1 0 auto;
    align-items: self-end;
    justify-content: right;
}

[data-role="downloadtracker"] > div
{
    flex: 0 1 170px;
    margin: 0;
    border: 1px solid #CCC;
    width: 170px;
    padding: 4px 8px;
    font-weight: bold;
    color: var(--abcblue);
}

[data-role="downloadtracker"] > div:first-child
{
    border-radius: 4px 0 0 4px;
}

[data-role="downloadtracker"] > div:last-child
{
    border-radius: 0 4px 4px 0;
    border-left-width: 0;
}

[data-role="downloadtracker"] .icon
{
    display: inline-block;
    margin-right: 8px;
}

[data-role="detailheader"]
{
    margin-top: 16px;
    clear: both;
}

.downloadcontainer
{
    text-align: right;
    clear: both;
    margin: 12px 0;
}

.downloadcontainer > *
{
    margin-right: 8px;
}

.downloadcontainer > *:last-child
{
    margin-right: 0;
}

.icsstyle
{
    background: #FFFFFF !important;
    color: var(--abcblue) !important;
    border-color: var(--abcblue) !important;
    border-radius: 6px;
    padding: 7px;
    font: bold 0.9em/1 "AB Gilroy";
    margin: 0;
    position: relative;
    top: 3px;
}